
import { Vue, Prop, Component, ProvideReactive } from "vue-property-decorator"
import { VCard } from "vuetify/lib"

@Component({
  components: {
    "v-card": VCard,
  },
  inject: [],
})
export default class Wizard extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly subtitle!: string
  @Prop({ required: false }) readonly width?: string

  @ProvideReactive()
  currentStepIndex = 0

  @ProvideReactive()
  steps = this.$slots.default?.filter((slot) => !!slot.tag).length || 0

  currentPage() {
    return this.$slots.default?.filter((slot) => !!slot.tag)?.[this.currentStepIndex]
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render(h: any) {
    return (
      <section>
        <span class="d-flex flex-column align-center text-center">
          <header2 class="mb-0 pb-0 text-center text-h5">{this.title}</header2>
          <p class="mt-0 pt-0 text--secondary">{this.subtitle}</p>
        </span>
        <v-card width={this.width} class="scope__development-card mt-5 pa-4" flat>
          {this.currentPage()}
        </v-card>
      </section>
    )
  }

  get isLastStep() {
    return this.currentStepIndex === this.steps - 1
  }

  get hasPreviousStep() {
    return this.currentStepIndex > 0
  }

  resetStepNavigation() {
    this.currentStepIndex = 0
  }

  goToPreviousStep() {
    if (this.currentStepIndex === 0) {
      return
    }

    this.currentStepIndex--
  }

  goToNextStep() {
    if (this.isLastStep) {
      return
    }

    this.currentStepIndex++
  }
}
