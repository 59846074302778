import i18n from "./"

export type UseTranslationReturn = {
  t: (key: string) => string
}

export const useTranslation = (scope: string): UseTranslationReturn => {
  const t = (key: string) => String(i18n.t(`${scope}.${key}`))

  return { t }
}
