import { inject, provide } from "vue"
import type { ProjectDetailsForm, ClientFormValues } from "../types"
import { ProvidedRefs } from "@/types"
import { useAccountContext } from "@/hooks/useAccountContext"

type NewProjectFormValues = {
  serviceForm: ProjectDetailsForm | null
  clientForm: ClientFormValues | null
}

type UseNewProjectContextReturn = {
  formValues: ProvidedRefs<NewProjectFormValues>
  updateServiceForm: (newFormValues: Partial<ProjectDetailsForm>) => void
  updateClientForm: (newFormValues: Partial<ClientFormValues>) => void
  isStaff: boolean
}

const providerKey = Symbol("formValues")

export const provideNewProjectValues = (values: ProvidedRefs<NewProjectFormValues>): void => {
  return provide(providerKey, values)
}

export const useNewProjectContext = (): UseNewProjectContextReturn => {
  const values = inject(providerKey) as ProvidedRefs<NewProjectFormValues>

  if (!values) {
    throw new Error("useNewProjectContext must be used within a NewProjectWizard")
  }

  const updateServiceForm = (newFormValues: Partial<ProjectDetailsForm>) => {
    values.serviceForm.value = {
      ...values.serviceForm.value,
      ...newFormValues,
    } as ProjectDetailsForm
  }

  const updateClientForm = (newFormValues: Partial<ClientFormValues>) => {
    values.clientForm.value = { ...values.clientForm.value, ...newFormValues } as ClientFormValues
  }

  const { isStaff } = useAccountContext()

  return { formValues: values, updateServiceForm, updateClientForm, isStaff }
}
